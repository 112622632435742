<template>
  <div style="background: #f5f5f5; padding-bottom: 81px;">
    <!-- 大图部分 -->
    <BigBgimg :list="bigData"></BigBgimg>
    <div class="ser_conter">
      <h2 class="h2">隐私保护政策</h2>
      <p>发布日期：2022年3月15日</p>
      <p>生效日期：2022年3月15日</p>
      <p>
        感谢您使用拓幻科技官方网站（https://www.tillusory.com.cn，以下简称“拓幻官网”），拓幻官网由南京拓幻智能科技有限公司（以下简称“拓幻科技”或“我们）运营。
      </p>
      <p>
        南京拓幻智能科技有限公司及其关联方（以下简称“<span class="blod"
          >拓幻</span
        >”或“<span class="blod">我们</span
        >”）深知个人信息对拓幻客户（以下简称“<span class="blod">客户</span
        >”）及终端个人用户（以下简称“<span class="blod">用户</span>”或“<span
          class="blod"
          >您</span
        >”）的重要性。我们将竭尽全力保护用户的个人信息安全。我们将按国家法律法规要求，恪守权责一致、目的明确、选择同意、最少够用、公开透明、确保安全、主体参与的原则，保护您的个人信息。为方便为您提供更个性化的用户体验和服务，您在访问拓幻官网或使用我们的产品或服务时，我们可能会收集和使用您的相关信息。我们希望通过本隐私声明向您说明，在使用拓幻科技相关产品及服务或访问拓幻官网（统称“本服务”）时，我们如何收集、使用、储存和披露您的信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本隐私声明与您所使用的拓幻官网、拓幻科技产品及服务息息相关，希望您仔细阅读。
      </p>
      <p class="blod">
        您访问拓幻官网或使用我们的产品或服务时，即意味着您接受且完全理解本声明的全部内容，并已经同意我们按照本隐私声明收集、使用、储存和披露您的相关信息，以及向您提供的控制和保护措施。
        本声明将帮助您了解以下内容：
      </p>

      <ul class="ul">
        <li>一、 声明适用范围</li>
        <li>二、 信息收集范围</li>
        <li>三、 如何收集信息</li>
        <li>四、 信息使用</li>
        <li>五、 信息披露</li>
        <li>六、 信息存储</li>
        <li>七、 信息安全</li>
        <li>八、 访问与控制</li>
        <li>九、 未成年人个人信息的处理</li>
        <li>十、 变更</li>
        <li>十一、 与我们联系</li>
        <li>十二、 声明的生效</li>
      </ul>

      <h3 class="h3">一、声明适用范围</h3>
      <p>
        本隐私声明中的“信息”特指第二条所收集的信息，本隐私声明适用于我们向您提供的服务。我们可能针对其他线上线下服务或产品制定保护政策、声明、通知或另行前述相关协议等（以下简称“其他政策”）。在其他政策有专门规定的情况下，以该等其他政策为准；如无该等其他政策或有未尽之处，则以本隐私声明为准。
        需要特别说明的是，本政策不适用于其他第三方（包括但不限于拓幻科技合作代理商等）向您提供的服务。例如您通过拓幻官网链接到的第三方服务或网站，您理解这些服务由第三方独立向您提供，第三方将自行依照其政策或用户协议单独对您的信息处理承担责任。
      </p>

      <h3 class="h3">二、信息收集范围</h3>
      <p>
        2.1
        为完成拓幻科技秘钥账户的注册登记、管理、实名认证等必要活动，您需要提交真实、合法、有效的信息，包括但不限于名称、联系人、电子邮箱、联系电话、联系地址、银行账户信息、工商登记信息等。请注意，该等过程是使用拓幻科技部分服务的前提之一，如拒绝提供该等信息将无法正常使用该等服务。
      </p>
      <p>
        2.2
        为提供持续服务和保证服务质量所需，我们在您使用本服务期间，将记录并保存您登录和使用本服务的信息，包括但不限于
        IP
        地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息、用户标识符及网页浏览记录等。
      </p>
      <p>
        2.3
        如果您需要使用部分可选的拓幻提供的功能或服务，如TiSDK等，您还应该提供使用该功能或服务所必须的信息，如手机型号、APP包名、应用名等。请注意，该等信息是使用拓幻该部分服务的前提之一，在您使用该部分服务时，我们会获取该部分信息对所用服务进行权限鉴定，如拒绝提供将无法使用该等服务。
      </p>
      <p>
        2.4
        如果您需要在线上申请线下交付或进行产品测试，为履行合同所必需，您应提供联系人、联系方式、地址等必要信息。
      </p>
      <h3 class="h3">三、如何收集信息</h3>
      <p>
        3.1
        我们会在如下场景和业务活动中收集您在使用官网及相关服务时主动提供的用户信息，以及我们通过自动化手段收集您在使用官网及相关服务过程中产生的用户信息，包括但不限于用户向平台提供的个人信息。
      </p>
      <p class="blod">
        特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份且与您无关，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份或与您有关时，或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，则在结合使用期间，这些信息将作为您的个人信息按照本隐私政策处理与保护。需要澄清的是，个人信息不包括经匿名化处理后的信息。
      </p>
      <p>
        3.2
        当您使用TiSDK等产品时，SDK产品会在内部自动收集和使用您的信息，这些信息包括：
        APP包名、应用名等
      </p>

      <h3 class="h3">四、信息使用</h3>
      <p>
        <ul class="ul">
          <li>向您提供服务。</li>
        <li>
          满足您的个性化需求。例如个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。
        </li>
        <li>
          服务优化和开发。例如，我们会根据拓幻相关服务响应您的需求时产生的信息，优化我们的服务。
        </li>
        <li>
          保护拓幻科技及其合作伙伴。例如，我们会将您的信息用于身份验证、权限验证等用途。例如，您在使用我们TiSDK时，验证您是否有使用该SDK的权限。
        </li>
        <li>
          其他可能需要使用收集的信息的相关场景，如果使用场景与初始场景无合理的关联性，我们会在使用信息前重新征得您的同意。
        </li>
        </ul>
      </p>    

        <h3 class="h3">五、信息披露</h3>
        <p>未经您同意，我们不会与任何无关第三方分享您的信息，以下情形除外：</p>
        <p>
          5.1 我们可能将您的信息与我们的关联公司、第三方服务提供商、承包商及代理分享，仅用作下列用途：
        <ul class="ul">
          <li>
             提供“二、信息收集范围”中的相应功能或服务所必需，以及出于“四、信息使用”中的部分所述目的所必需。
          </li>
          <li>
            履行我们在双方合作协议或本声明或其他政策中的义务和行使我们的权利。
          </li>
        </ul>
        </p>
        <p>如我们与任何上述第三方分享您的信息，我们将努力确保第三方在使用您的信息时遵守本声明及我们要求其遵守的其他适当的保密和安全措施。</p>
        <p>5.2 随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的信息有可能作为此类交易的一部分而被转移。我们将遵守相关法律法规的要求，在转移前通知您，确保信息在转移时的机密性，以及变更后继续履行相应责任和义务。</p>
<p>
  5.3 我们还可能因以下原因而披露您的信息：
  <ul class="ul">
    <li>遵守适用的法律法规等有关规定。</li>
    <li>与国家安全、国防安全等国家利益直接相关的；与公共安全、公共卫生、公众知情等重大公共利益直接相关的。</li>
    <li>遵守法院判决、裁定或其他法律程序的规定；或与犯罪侦查、起诉、审判和判决执行等直接相关的。</li>
    <li>遵守相关政府机关或其他有权机关的要求。</li>
    <li>我们有理由确信需遵守法律法规等有关规定。</li>
    <li>为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。</li>
    <li>所收集的用户信息是您自行向社会公众公开的；</li>
    <li>从合法公开披露的信息中收集用户信息的，如合法的新闻报道、政府信息公开等渠道；</li>
    <li>根据您要求签订和履行合同所必需的；</li>
    <li>出于维护您或其他个人的生命、财产、声誉等重大合法权益但又很难得到本人同意的。</li>
    <li>用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</li>
    <li>为开展合法的新闻报道所必需的；</li>
    <li>出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的用户信息进行去标识化处理的；</li>
    <li>经过您合法授权的情形。</li>
  </ul>
</p>
<p>如我们因上述原因而披露您的信息，我们将在遵守法律法规相关规定及本声明的基础上及时告知您。</p>
     
        <h3 class="h3">六、信息存储</h3>
        <p>6.1 我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。</p>
        <p>6.2 通常，我们仅在为您提供服务期间保留您的信息，保留时间不会超过满足相关使用目的所必须的时间。</p>
        <p>但在下列情况下，且仅出于下列情况相关的目的，我们有可能需要较长时间保留您的信息或部分信息：
        <ul class="ul">
          <li>
            遵守适用的法律法规等有关规定。
          </li>
          <li>
           遵守法院判决、裁定或其他法律程序的要求。
          </li>
          <li>遵守相关政府机关或其他有权机关的要求。</li>
          <li>我们有理由确信需遵守法律法规等有关规定。</li>
          <li>为执行相关服务协议或本隐私声明、维护社会公共利益、处理投诉/纠纷，保护我们的客户、我们或我们的关联公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。</li>
        </ul>
        </p>

        <h3 class="h3">七、信息安全</h3>
      <p>7.1 如果您拥有拓幻TiSDK平台等其他账号、秘钥，在特定场景下，您在该部分的个人信息可能或未来可能会与您在拓幻科技、及其关联公司拥有的其他账户信息相关联。</p>
      <p>7.2 我们努力保障信息安全，以防信息的丢失、不当使用、未经授权阅览或披露。
        <ul class="ul">
          <li>我们使用各种安全技术以保障信息的安全。例如，我们将通过服务器多备份、密码加密等安全措施，防止信息泄露、毁损、丢失。</li>
          <li>我们建立严格的管理制度和流程以保障信息的安全。例如，我们严格限制访问信息的人员范围，并进行审计，要求他们遵守保密义务。</li>
        </ul>
      </p>
      <p>但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网环境下，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</p>
      <p>若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，按照《国家网络安全事件应急预案》等有关规定及时上报，并以发送邮件、推送通知、公告等形式告知您相关情况，并向您给出安全建议。</p>
      <p>7.3 为更有效的保障您的信息安全，我们也希望您能够加强自我保护意识。我们仅在本服务直接导致您个人信息泄露的范围内承担责任，因此，请您妥善保管您的账号及密码等各项信息，避免您的个人信息泄露。，除非您判断认为必要的情形下，不向任何第三人提供您的账号密码等个人信息。</p>

       <h3 class="h3">八、访问与控制</h3>
       <p>8.1 您可以在使用我们服务的过程中，访问、修改和删除您的相关信息。您访问、修改和删除信息的方式将取决于您使用的具体服务。但请您知悉，个人信息的修改和删除可能会影响该信息所涉及的相关服务的使用或导致该服务不可用。</p>
       <p>8.2 如您发现我们违反法律法规的规定或者双方的约定收集、使用您的信息，您可以要求我们删除。如您发现我们收集、存储的您的信息有错误的，且无法自行更正的，您也可以要求我们更正。</p>
       <p>8.3 在访问、修改和删除相关信息时，我们可能会要求您进行身份验证，以保障账户安全。请您理解，由于技术所限、基于法律法规要求，您的某些请求可能无法进行响应。</p>
       <p>8.4 基于法律法规要求、保障信息安全等正当事由，您的部分信息可能无法访问、修改和删除。</p>
       <p>8.5 您可以自行选择撤回对某些非基本功能或服务对您信息处理的同意，并通过联系客服的方式选择注销拓幻账号。</p>
       <p>8.6 如您对上述权利实现存在疑问，可以根据“十一、与我们联系”中的相关联系方式与我们取得联系。</p>

       <h3 class="h3">九、未成年人个人信息的处理</h3>
       <p>9.1我们的产品、网站和服务主要面向成人。未经监护人的同意，未成年人不得使用我们的产品及服务。未成年人应在其父母或监护人的同意、指导和监督下使用我们的产品及服务。</p>
       <p>9.2对于经父母或监护人同意而收集的未成年人，尤其是儿童的个人信息，我们只会在法律法规允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用、共享或披露此信息。</p>
       <p>9.3若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请通过本声明说明的方式与我们联系。</p>

       <h3 class="h3">十、变更</h3>
       <p>我们可能适时修订本隐私声明的条款，该等修订构成本《隐私声明》的一部分。如可能造成您在本隐私声明下权利的实质减少或扩大收集、使用信息的范围等重要规则变更时，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您，或直接对本声明作更换，您亦可以随时在拓幻官网查看最新的隐私声明。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本隐私声明的约束。</p>
       
        <h3 class="h3">十一、与我们联系</h3>
       <p>如果您对本隐私声明有任何疑问、意见或建议，或发现您的个人信息被泄露，可以通过客服与我们联系。邮箱：business@tillusory.cn。</p>

        <h3 class="h3">十二、声明的生效</h3>
       <p>12.1本声明自发布之日起生效。</p>
       <p>12.2本政策的生效、履行、解释及争议、纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。您同意与我们的任何争议或纠纷在无法协商一致时，向南京市雨花台区人民法院起诉，通过诉讼方式解决。</p>
      
    </div>
  </div>
</template>
<script>
import BigBgimg from "@/components/BigBgimg/index"; //大图
export default {
  components: { BigBgimg },
  data() {
    return {
      // 大图数据
      bigData: {
        topTitle: "隐私保护政策",
        topText: "完整共赢的协议为您提供更规划化的服务，更轻松的业务合作",
        topBtn: "联系我们",
        topBg: require("@/assets/home/ser/服务协议首图.png"),
      },
    };
  },
  methods: {},
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.ser_conter {
  width: 1140px;
  height: 1070px;
  margin: 82px auto 0;
  padding: 35px;
  background: #ffffff;
  overflow-y: scroll;
  font-size: 16px;
  color: #333;
  text-indent: 2em;
  line-height: 24px;

  .h2 {
    text-align: center;
    margin-bottom: 28px;
    font-size: 30px;
    font-weight: 500;
    color: #222222;
    line-height: 38px;
  }
  .h3 {
    font-size: 16px;
    font-weight: 500;
    color: #222222;
    line-height: 38px;
  }
  .ul {
    li {
      font-weight: 400;

      list-style: disc;
      list-style-position: inside;
    }
  }

  .blod {
    font-weight: bold;
  }
}
</style>